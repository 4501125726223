var slick = require('../vendor/slick.min.js');
var sumo = require('sumoselect');
// var Slider = require('../../js/sliders.js');
// var AOS = require('aos');

require('jquery-ui');

import {
    CountUp
} from 'countup.js';

import Utilities from './utilities/utilities';
const utility = new Utilities;

import Scrollbar from "./utilities/scrollbar/scrollbar";
const scrollbar = new Scrollbar();

$(document).ready(function () {
    scrollbar.init();

    var windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (windowWidth < 992) {
        $('.dropdown-toggle').dropdown();
    } else {
        $('.dropdown-toggle').click(function (e) {
            e.preventDefault();
        });
    }

    // Anchor link scrollTop animation
    if ($('[data-srcset="background"]').length) {
        utility.bgSrcset($('[data-srcset="background"]'));
    }

    $(document).on('click', '.anchor', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var id = $(this).attr('href');
        if ($(id).length) {
            $('html, body').animate({
                scrollTop: $(id).offset().top - 90
            });
        }
    });

    const bktt = $('#back_to_top');
    bktt.on('click', function (e) {
        e.preventDefault();
        utility.scrollToTop();
    });

    //
    // Navigation animation
    //
    var menu = $(document).find('[data-menu]').attr('data-menu');
    $(document).on('click', '[data-menu]', function (e) {
        e.preventDefault();
        $(menu).toggleClass('open');
        $(menu).find('.main-navigation').toggleClass('show');
        $('.nav-backdrop').toggleClass('show');
        $('body').toggleClass('menu-is-open');
    });

    //
    // Cookie set and link action
    //
    var cookie_name = 'seenCookieMessage';
    //CookieMonster.eraseCookie(cookie_name);
    /*$(document).on('click', '#btnCookie', function(e)  {
        e.preventDefault();
        e.stopPropagation();
        CookieMonster.setCookie(cookie_name, 'yes', 60);
        $('.cookie-component').addClass('close-cookie');
    });*/

    //
    // Close popups(...) when clicked outside of target element
    //

    // Definiranje visine contenta
    // utility.setEqualHeight('.carousel-item-title');
    // utility.setEqualHeight('.carousel-item-desc');
    // utility.setEqualHeight('.carousel-item-link');

    utility.setEqualHeight('.slider-link .item-title');
    utility.setEqualHeight('.slider-link .item-desc');

    utility.setEqualHeight('.grid-item-title');
    utility.setEqualHeight('.grid-item-text');
    utility.setEqualHeight('.blog-title');
    utility.setEqualHeight('.blog-img-wrapper');
    utility.setEqualHeight('.blog-desc');
    // Kraj Definiranja visine contenta

    // CATEGORY  TAB SWITCHER
    $('.cat-btn').on('click', function (e) {
        e.preventDefault();
        var thisClass = $(this).attr('id');
        $('.all-btn').removeClass('active');
        $('.cat-btn').removeClass('active');
        $(this).addClass('active');
        $('.cat-sort').each(function () {
            if ($($(this)).hasClass(thisClass)) {
                $(this).show(400);
            } else {
                $(this).hide(400);
            }
        })
    });

    $('.all-btn').on('click', function (e) {
        e.preventDefault();
        $('.cat-btn').removeClass('active');
        $(this).addClass('active');
        $('.cat-sort').each(function () {
            $(this).show(400);
        })

    });

    var fullUrl = window.location.href;
    var referenceHash = window.location.hash;
    var referenceId = referenceHash.slice(1);
    $('.cat-btn').each(function () {
        if ($(this).attr('id') === referenceId) {
            $(this).trigger('click');
        }
    });

    if ($('.header-slider-item').length) {
        $('.header-slider-item').each(function (el) {
            if ($(this).find('.video').length) {
                var _this = $(this).find('.video')[0];
                var videoSrc = _this.getAttribute('data-src');

                _this.setAttribute('src', videoSrc)
            }
        });
    }

    $('select.filter-select').SumoSelect();

    $('select.filter-select').on('change', function (e) {
        window.location.href = $(this).val();
    });
});

$(window).on('scroll', function () {
    if ($('.dm_counter_slider').length) {
        var eT = $('.dm_counter_slider').offset().top,
            eH = $('.dm_counter_slider').outerHeight(),
            wH = $(window).height(),
            wS = $(this).scrollTop();
        if (wS > (eT + eH - wH)) {
            $(window).off('scroll');

            // animateCounter();
            countNumber();
        }
    }
});

// YouTube custom play button
// Fades out cover image and button on click and starts video that is behind button and/or image
$(document).on('click', '#video-play-lazy', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    var iframe = document.createElement('iframe');
    var embed = $(this).parent().data('embed');
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("src", "https://www.youtube.com/embed/" + embed + "?enablejsapi=1&version=3&playerapiid=ytplayer");
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    $(this).parent().append(iframe);
    setTimeout(function () {
        _this.parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }, 500);
});
$(document).on('click', '#video-play-normal', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    $(this).parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');

});

// pause video on click
$(document).on('click', '.navi', function (e) {
    e.preventDefault();
    for (var i = 0; i < $(this).parent().find('iframe').length; i++) {
        $(this).parent().find('iframe')[i].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    }
});

$(function () {
    $('.nav-item').each(function () {
        var windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var navItemWidth = parseInt($('.nav-item').css('width'));
        var navItemPLeft = parseInt($('.nav-item').css('padding-left'));
        var navItemPRight = parseInt($('.nav-item').css('padding-right'));
        var navItemPadding = navItemPLeft + navItemPRight;
        $('.nav-link').each(function () {

            var navLinkWidth = parseInt($(this).css('width'));
            var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 15);
            $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            if (windowWidth < 370) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 20);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }
            if (windowWidth < 290) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 25);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }
            if (windowWidth < 237) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 30);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }

        })
    });
})

function animateCounter() {
    function commaSeparateNumber(val) {
        while (/(\d+)(\d{3})/.test(val.toString())) {
            val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
        }
        return val;
    }

    $('.count').each(function () {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
        }, {
            duration: 8000,
            easing: 'swing',
            step: function (now) {
                $(this).text(commaSeparateNumber(Math.ceil(now)));
            }
        });
    });
}

function countNumber() {
    const optionsCounter = {
        duration: 3,
        separator: '.',
    };
    $('.count[data-count]').each(function () {
        var countTo = Number($(this).data('count'));

        let demo = new CountUp($(this).attr('id'), countTo, optionsCounter);
        if (!demo.error) {
            demo.start();
        } else {
            console.error(demo.error);
        }
    })
}

utility.navbarScroll();
utility.defineBackToTop();

// No results page -> trigger detail search
$(document).on('click', '.look-again', function (e) {
    $('.show-detail-search a').trigger("click");
});

// Removing empty ul from main navigation dropdown
$(document).ready(function() {
    var navDropdownUl = $('.header-nav .property-dropdown .dropdown-menu ul');

    $(navDropdownUl).each(function() {
        if ($(this).find('li').length === 0) {
            $(this).remove();
        }
    });
});

// Detail search dont show action selector if only one is available
$( document ).ready(function() {
    if($('.detail-search .sumo_action select option').length < 2){
        $('.sumo_action').parent().css('display', 'none');
    }
});
if ($('body').hasClass('homepage')) {
    var slickOptions = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        prevArrow: $('.properties-home-prev-btn'),
        nextArrow: $('.properties-home-next-btn'),
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1265,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    };

    $.ajax({
        url: $('#homepage-special-offer').attr('data-path') + '?v=v5&limit=8&specialOffer=1&order=RAND_asc',
        success: function (r, status, jqXHR) {
            $('.special-offer-wrapper .preloader').hide();
            $('#homepage-special-offer').append(r).fadeIn();
            settingCardImagesDimensions();
            $('#homepage-special-offer').slick(slickOptions);
        }
    });
}

$(function () {
    $('#menu').appendTo('.nav-mob-wrap');
    $('.header-container').detach('#menu');
});

// No results page -> trigger detail search
$(document).on('click', '.look-again', function (e) {
    $('.show-detail-search a').trigger("click");
});

// Accordion Homepage-reviews form
$('.homepage-reviews .accordion-btn').on('click', function (e) {
    e.preventDefault();

    var element = $('.homepage-reviews .form-wrapper');
    var height = element.prop('scrollHeight');

    if (element.hasClass('show')) {
        element.removeClass('show').css('height', 0);
        $(this).removeClass('rotate');
    } else {
        element.addClass('show').css('height', height + 0);
        $(this).addClass('rotate');
    }
});